<template>
  <div class="post-image-container">

    <p class="date" :title="post.createdAt">{{ post.createdAt | date }}</p>

    <div class="post-image">
      <div :style="preview"></div>
    </div>

  </div>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'FeedPostImage',

  props: {
    post: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    preview() {
      if ( !this.post.image ) return null
      return `background-image: url(${ this.post.image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.post-image {
  background-color: $light-grey;
  border: 1px solid $med-light-grey;
  border-radius: 2px;
  overflow: hidden;

  &-container {
    width: 100%;
    grid-area: image;

    div {
      @include image-center;
      height: 180px;
      width: 100%;
      min-width: 180px;
      margin: 0 auto;
      border-radius: 1px;

      @media ( max-width: $tablet ) {
        min-width: unset;
      }
    }

    //Date
    p {
      text-align: right;
      font-size: 12px;
      color: $med-dark-grey;
      cursor: default;
      padding-bottom: 12px;

      @media ( max-width: $mobile ) {
        display: none;
      }
    }
  }
}
</style>
