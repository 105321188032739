
<template>
  <div class="post-header">

    <div class="user" :title="name">
      <router-link class="profile" :alt="name" :style="style" :to="{ name: 'users', params: { id: post.user }}">
        <svg v-if="icon" v-html="icon"/>
      </router-link>
      <p>{{ name }}</p>
    </div>

    <p :class="{ hasImage: post.image }" :title="post.createdAt" class="date">{{ post.createdAt | date }}</p>

  </div>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'FeedPostHeader',

  props: {
    post: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    style() {
      return this.$Utils.User.image( this.post.profile, this.post.color )
    },

    name() {
      return this.$Utils.User.name( this.post )
    },
    
    icon() {
      if ( this.post.profile ) return
      return this.$Utils.User.icon( this.post.icon )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.post-header {
  @include flex-config( false, space-between, flex-start );
  font-size: 16px;
  grid-area: header;

  .user {
    @include flex-config( false, flex-start, center );
    cursor: pointer;
    border-bottom: 1px solid $med-light-grey;
    padding-bottom: 8px;

    p {
      display: inline;
      padding: 0 8px;
      font-size: 16px;
    }
  }

  //Date
  .date {
    text-align: right;
    font-size: 12px;
    color: $med-dark-grey;
    cursor: default;

    &.hasImage {
      display: none;

      @media ( max-width: $mobile ) {
        display: block;
      }
    }
  }
}
</style>
