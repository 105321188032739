<template>
  <div class="comments-wrapper">

    <ul v-if="comments.length && loaded">
      <Comment v-for="( comment, index ) in comments" :key="index" :comment="comment"/>
    </ul>

    <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
      <p>{{ label }}</p>
      <img v-if="!loading" :src="icons.load" alt="Load more"/>
    </button>

    <form @submit.prevent="submit">
      <input type="text" placeholder="Write a comment" v-model="text" :maxlength="max"/>
    </form>

  </div>
</template>

<script>
import Comment from '@/components/feed/comments/Comment.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FeedPostComments',

  data() {
    return {
      loading: false,
      loaded: false,
      sending: false,
      error: null,
      text: '',
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' )
      }
    }
  },

  components: {
    Comment
  },

  props: {
    post: Object
  },

  methods: {
    ...mapActions( 'Comments', {
      fetch: 'get',
      new: 'new'
    }),

    async load() {
      if ( !this.canLoadMore ) return

      this.loading = true
      this.error = null

      await this.fetch({ post: this.id, offset: this.offset })

      this.loading = false
    },

    async submit() {
      if ( !this.text.trim() || this.sending ) return

      const text = this.text
      this.text = ''
      this.sending = true

      await this.new({ post: this.id, text }).catch( e => this.error = e )

      this.sending = false
    }
  },

  computed: {
    ...mapGetters( 'Comments', {
      get: 'get'
    }),

    ...mapGetters( 'Limits', {
      limit: 'comments'
    }),

    id() {
      return this.post.id
    },

    max() {
      return this.limit.characters
    },

    comments() {
      return this.get( this.id )
    },

    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    canLoadMore() {
      return this.post.children > this.comments.length || !this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && this.post.children > 0 
    },

    offset() {
      return !this.loaded ? 0 : this.comments.length
    }
  },

  async mounted() {
    if ( this.post.children ) await this.load()
    this.loaded = true
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.comments-wrapper {
  padding-top: 12px;
    
  form {
    input {
      font-size: 11px;
      height: 22px;
      box-shadow: none;
      border: 1px solid $med-light-grey;
      padding: 12px 8px;
    }
  }

  ul {
    margin-bottom: 12px;
  }

  button.pagination {
    background-color: unset;
    padding: 0 0 12px 0;
    border: none;
  }
}
</style>
