<template>
  <div class="feed-wrapper">
    <div ref="scroller" v-on:scroll="scrolling" class="feed">

      <!-- Content -->
      <Posts v-if="showPosts"/>

      <!-- Error and Loading -->
      <div v-else class="retry">
        <div>

          <h2 v-if="showError" class="bk">{{ error || 'No posts yet, be the first!' }}</h2>
          <button role="button" v-if="showError" alt="Reload" @click="reload" class="submit circle retry">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><img :src="icons.reload" alt="Reload"/></span>
          </button>

          <Loading v-if="loading"/>

        </div>
      </div>

      <!-- New post modal -->
      <New v-if="modal" :close="added"/>

      <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
        <p>{{ label }}</p>
        <img v-if="!loading" :src="icons.load" alt="Load more"/>
      </button>

    </div>

    <!-- Write a new post -->
    <button role="button" v-if="showNew" alt="New" class="submit circle new" @click.stop="modal = true" title="New post">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icons.new" alt="Plus"/></span>
    </button>

  </div>
</template>

<script>
import Posts from '@/components/feed/posts/Container.vue'
import New from '@/components/feed/posts/New.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Feed',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      modal: false,
      icons: {
        new: require( '@/assets/icons/shared/plus.svg' ),
        load: require( '@/assets/icons/shared/expand.svg' ),
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Posts,
    New,
    Loading
  },

  computed: {
    ...mapGetters( 'Posts', {
      isEmpty: 'isEmpty',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'posts'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showPosts() {
      return !this.error && !this.isEmpty && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showPosts
    },

    showError() {
      return ( this.error || this.isEmpty ) && !this.loading
    },

    showNew() {
      return this.loaded //&& !this.modal
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Posts', {
      fetch: 'get'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true
      
      const count = await this.fetch({ offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error

      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    },

    added() {
      //When a new post is added, close the modal and scroll to the top
      this.modal = false
      this.error = null
      if ( this.scroller ) this.scroller.scrollTop = 0
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.feed {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow-y: scroll;
  text-align: center;

  button.pagination {
    margin-bottom: 16px;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    max-width: $max-width;
    margin: 0 auto;
    height: auto;
    overflow-y: hidden;

    button.new {
      margin: 0 24px 64px 0;
    }
  }
}
</style>
