<template>
  <ul class="feed-list">

      <Post v-for="post in feed" :key="post.id" :post="post"/>

  </ul>
</template>

<script>
import Post from '@/components/feed/posts/Post.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FeedPosts',

  components: {
    Post
  },

  computed: {
    ...mapGetters( 'Posts', {
      feed: 'posts'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.feed-list {
  padding: $page-padding;
  width: 100%;
}
</style>
