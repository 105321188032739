<template>
  <li class="post">
    <article :class="{ hasImage: post.image, 'temp': temp }">

      <Header :post="post"/>

      <Content :text="post.text"/>

      <Attachment v-if="post.image" :post="post"/>

      <!-- Includes the comments -->
      <Footer :post="post"/>

    </article>
  </li>
</template>

<script>
import Header from '@/components/feed/posts/Header.vue'
import Content from '@/components/feed/posts/Content.vue'
import Attachment from '@/components/feed/posts/Image.vue'
import Footer from '@/components/feed/posts/Footer.vue'

export default {
  name: 'FeedPost',

  components: {
    Header,
    Content,
    Attachment,
    Footer
  },

  props: {
    post: Object
  },

  computed: {
    temp() {
      return this.$Utils.Validation.uuid( this.post.id )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.post {
  width: 100%;
  position: relative;

  &:not( :first-child ) {
    margin-top: 16px;
  }

  article {
    background: $gradient-white;
    border-radius: 4px;
    width: 100%;
    padding: 12px;
    display: grid;
    grid-template-rows: repeat( 3, auto );
    grid-gap: 12px;
    @include box-shadow;

    &.temp {
      opacity: $opacity-half;
      pointer-events: none;
    }

    grid-template-areas: "header" "content" "footer";
    grid-template-columns: 1fr;

    &.hasImage {
      grid-template-areas: "header image" "content image" "content image" "footer footer";
      grid-template-rows: auto 1fr 1fr auto;
      grid-template-columns: 1fr 1fr;

      @media ( max-width: $mobile ) {
        grid-template-areas: "header" "image" "content" "footer";
        grid-template-rows: auto auto 1fr auto;
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
