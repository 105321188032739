<template>
  <div class="post-footer">

    <div class="wrapper">
      <div class="comments" @click="showComments = !showComments">
        <p>{{ label }}</p>
        <img :class="{ open: showComments }" :src="icons.expand" alt="Expand"/>
      </div>

      <div class="votes">
        <span @click="up()" title="Upvote" :class="{ active: upvoted( id )}">
          <svg stroke="#121212" fill="none" stroke-width="1px" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
        </span>

        <p :title="post.votes">{{ post.votes }}</p>

        <span @click="down()" title="Downvote" :class="{ active: downvoted( id )}">
          <svg class="down" stroke="#121212" fill="none" stroke-width="1px" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="none"><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path></svg>
        </span>
      </div>
    </div>

    <Comments v-if="showComments" :post="post"/>

  </div>
</template>

<script>
import Comments from '@/components/feed/comments/Container.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FeedPostFooter',

  data() {
    return {
      showComments: false,
      loading: false,
      icons: {
        expand: require( '@/assets/icons/shared/expand.svg' )
      }
    }
  },

  props: {
    post: Object
  },

  components: {
    Comments
  },

  methods: {
    ...mapActions( 'Posts', {
      upvote: 'upvote',
      downvote: 'downvote'
    }),

    async up() {
      if ( this.loading ) return
      this.loading = true
      const id = this.id
      this.isUpvoted ? await this.downvote({ id }) : this.upvote({ id, client: true })
      this.loading = false
    },

    async down() {
      if ( this.loading ) return
      this.loading = true
      const id = this.id
      this.isDownvoted ? await this.upvote({ id }) : this.downvote({ id, client: true })
      this.loading = false
    }
  },

  computed: {
    ...mapGetters( 'Posts', {
      upvoted: 'upvoted',
      downvoted: 'downvoted'
    }),

    id() {
      return this.post.id
    },

    isUpvoted() {
      return this.upvoted( this.id )
    },

    isDownvoted() {
      return this.downvoted( this.id )
    },

    label() {
      const children = this.post.children
      return `${ children } Comment${( children == 1 ? '' : 's' )}`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.post-footer {
  grid-area: footer;

  .wrapper {
    @include flex-config( false, space-between, flex-end );
  }

  p {
    font-size: 13px;
    color: $med-dark-grey;
  }

  .comments {
    @include flex-config( false, flex-start, center );
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin: 3px 0 0 6px;

      &.open {
        transform: scale( 1, -1 );
      }
    }

    p {
      color: $dark-grey;
    }
  }

  .votes {
    @include flex-config( false, center, center );

    //Thumbs icon
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      cursor: pointer;
      @include flex-center;
      stroke: $med-dark-grey;

      &.active svg, :hover {
        fill: rgba( $primary-color, $opacity-med );
      }

      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;

        &.down {
          transform: scale( -1, -1 );
          margin-top: 4px;
          margin-bottom: 0;
        }
      }
    }

    p {
      margin: 0 10px;
      font-size: 14px;
      color: $off-black;
    }
  }
}
</style>
