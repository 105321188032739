<template>
  <div class="post-content">

    <p>{{ text }}</p>

  </div>
</template>

<script>

export default {
  name: 'FeedPostContent',

  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.post-content {
  @include flex-config( false, space-between, flex-start );
  grid-area: content;

  p {
    font-size: 16px;
    text-align: justify;
  }
}
</style>
