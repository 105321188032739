<template>
  <li :class="{ temp }">

    <router-link :title="name" :to="{ name: 'users', params: { id: comment.user }}">
      <p>{{ name }}: </p>
    </router-link>

    <p>{{ comment.text }}</p>

    <p class="date" :title="comment.createdAt">{{ comment.createdAt | date }}</p>
    
  </li>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'FeedPostComment',

  props: {
    comment: Object
  },

  filters: {
    ...Filters
  },

  computed: {
    name() {
      return this.$Utils.User.name( this.comment )
    },

    temp() {
      return this.$Utils.Validation.uuid( this.comment.id )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

li {
  border-top: 1px dashed $med-light-grey;
  padding: 6px 0;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 1fr auto;

  &:last-child {
    border-bottom: 1px dashed $med-light-grey;
  }

  &.temp {
    opacity: $opacity-half;
    pointer-events: none;
  }

  p, a p {
    font-size: 14px;
    text-align: left;
    color: $off-black;
  }
  
  .date {
    color: $dark-grey;
    text-align: right;
    font-size: 12px;
  }
}
</style>
